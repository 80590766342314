import Particles from "react-tsparticles"
import { Container } from "./styles"
import { Hero } from "../hero"
import { About } from "../about";
import { Contact } from "../contact";
import { Project } from "../project";


// tech-stacks
import gopher from "../../assets/tech-stacks/gopher.svg";
import java from "../../assets/tech-stacks/java.svg";
import python from "../../assets/tech-stacks/python.svg";
import typescript from "../../assets/tech-stacks/typescript.svg";

import postgres from "../../assets/tech-stacks/postgres.svg";
import mysql from "../../assets/tech-stacks/mysql.svg";
import scylladb from "../../assets/tech-stacks/scylladb.svg";

import redis from "../../assets/tech-stacks/redis.svg";

import nsq from "../../assets/tech-stacks/nsq.png"

import gcp from "../../assets/tech-stacks/gcp.svg";
import aws from "../../assets/tech-stacks/aws.svg";

// tools
import grafana from "../../assets/tools/grafana.svg";
import sentry from "../../assets/tools/sentry.svg";
import datadog from "../../assets/tools/datadog.svg";
import prometheus from "../../assets/tools/prometheus.svg";
import docker from "../../assets/tools/docker.svg";


export function Main() {
  return (
    <Container>
      <Particles
        id="tsparticles"
        options={{
          "fullScreen": {
            "enable": true,
            "zIndex": 1
          },
          "detectRetina": true,
          "fpsLimit": 60,
          "interactivity": {
            "events": {
              "onClick": {
                "enable": true,
                "mode": "push"
              },
              "onDiv": {
                "elementId": "repulse-div",
                "enable": false,
                "mode": "repulse"
              },
              "onHover": {
                "enable": true,
                "mode": "bubble",
                "parallax": {
                  "enable": false,
                  "force": 60,
                  "smooth": 10
                }
              },
              "resize": true
            },
            "modes": {
              "bubble": {
                "distance": 400,
                "duration": 2,
                "opacity": 0.8,
                "size": 2,
              },
              "connect": {
                "distance": 80,
                "lineLinked": {
                  "opacity": 0.5
                },
                "radius": 60
              },
              "grab": {
                "distance": 400,
                "lineLinked": {
                  "opacity": 1
                }
              },
              "push": {
                "quantity": 1
              },
              "remove": {
                "quantity": 1
              },
              "repulse": {
                "distance": 500,
                "duration": 0.8
              }
            }
          },
          "particles": {
            "color": {
              "value": "#ffffff"
            },
            "lineLinked": {
              "blink": false,
              "color": "#000",
              "consent": false,
              "distance": 150,
              "enable": false,
              "opacity": 0.4,
              "width": 1
            },
            "move": {
              "attract": {
                "enable": false,
                "rotate": {
                  "x": 600,
                  "y": 1200
                }
              },
              "bounce": false,
              "direction": "none",
              "enable": true,
              "outMode": "out",
              "random": false,
              "speed": 2,
              "straight": false
            },
            "number": {
              "density": {
                "enable": true,
                "area": 800
              },
              "limit": 30,
              "value": 15,
            },
            "opacity": {
              "animation": {
                "enable": true,
                "minimumValue": 0.2,
                "speed": 1,
                "sync": false
              },
              "random": true,
              "value": 1
            },
            "rotate": {
              "animation": {
                "enable": true,
                "speed": 5,
                "sync": false
              },
              "direction": "random",
              "random": true,
              "value": 0
            },
            "shape": {
              "character": {
                "fill": false,
                "font": "Verdana",
                "style": "",
                "value": "*",
                "weight": "400"
              },
              "image": [
                {
                  "src": gopher,
                  "width": 20,
                  "height": 20
                },{
                  "src": java,
                  "width": 20,
                  "height": 20
                },{
                  "src": python,
                  "width": 20,
                  "height": 20
                },{
                  "src": typescript,
                  "width": 20,
                  "height": 20
                },{
                  "src": postgres,
                  "width": 20,
                  "height": 20
                },{
                  "src": mysql,
                  "width": 20,
                  "height": 20
                },{
                  "src": scylladb,
                  "width": 20,
                  "height": 20
                },{
                  "src": redis,
                  "width": 20,
                  "height": 20
                },{
                  "src": nsq,
                  "width": 20,
                  "height": 20
                },{
                  "src": gcp,
                  "width": 20,
                  "height": 20
                },{
                  "src": aws,
                  "width": 20,
                  "height": 20
                },{
                  "src": grafana,
                  "width": 20,
                  "height": 20
                },{
                  "src": sentry,
                  "width": 20,
                  "height": 20
                },{
                  "src": datadog,
                  "width": 20,
                  "height": 20
                },{
                  "src": prometheus,
                  "width": 20,
                  "height": 20
                },{
                  "src": docker,
                  "width": 20,
                  "height": 20
                },

              ],
              "polygon": {
                "sides": 5
              },
              "stroke": {
                "color": "#000000",
                "width": 0
              },
              "type": "image"
            },
            "size": {
              "animation": {
                "enable": false,
                "minimumValue": 0.1,
                "speed": 40,
                "sync": false
              },
              "random": false,
              "value": 16
            }
          },
          "polygon": {
            "draw": {
              "enable": false,
              "lineColor": "#ffffff",
              "lineWidth": 0.5
            },
            "move": {
              "radius": 10
            },
            "scale": 1,
            "url": ""
          },
          "background": {
            "image": "",
            "position": "50% 50%",
            "repeat": "no-repeat",
            "size": "cover"
          }
        }}
      />
      <Hero/>
      <About/>
      <Project/>
      <Contact/>
    </Container>
  );
}