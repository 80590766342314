import { Container } from "./styles"
import ScrollAnimation from "react-animate-on-scroll"


import linkedin from '../../assets/social/linkedin.svg'
import github from '../../assets/social/github.svg'
import medium from '../../assets/social/medium.svg'

import hello from '../../assets/misc/Hello.gif'
import illustration from "../../assets/illustration.svg"

export function Hero() {
  return (
    <Container id="home">
      <div className="hero-text">
        <ScrollAnimation animateIn="fadeInUp">
          <p>Hello <img src={hello} alt="Hello" width="20px"/>, I'm</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
          <h1>Marcellus</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
          <h3>Senior Software Engineer</h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
          <p className="small-resume">@ JULO</p>
        </ScrollAnimation>


        <ScrollAnimation animateIn="fadeInUp" delay={0.8 * 1000}>
          <a href="https://calendly.com/c3llus"
            target="_blank"
            rel="noreferrer"
            className="button"
          >
            Schedule Call
          </a>
        </ScrollAnimation>
        
        <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
          <div className="social">
            <a
              href="https://www.linkedin.com/in/c3llus"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="Linkedin" />
            </a>

            <a
              href="https://www.github.com/c3llus/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={github} alt="GitHub" />
            </a>

            <a
              href="https://blog.c3llus.dev/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={medium} alt="Medium" />
            </a>
          </div>
        </ScrollAnimation>
      </div>

      <div className="hero-image">
        <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>
          <img src={illustration} alt="Illustration"/>
        </ScrollAnimation>
      </div>
     
    </Container>

  )
}