import styled from "styled-components";

export const Container = styled.section`
  padding-top: 15%;
  display: flex;
  justify-content: space-between;
  gap: 8rem;
  background: rgba(0,0,0,0);
  
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

`