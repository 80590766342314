import { GlobalStyle } from '../../common/styles/global';
import { Header } from './components/header';
import { Main } from './components/main';
import 'react-toastify/dist/ReactToastify.css'

export const HomePage = () => {
  return (
    <>
      <GlobalStyle/>
      <Header/>
      <Main/>
    </>
  );
}
