// import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { HomePage } from './homepage';
import { NotFound } from './notfound';


export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Static pages routes */}
                <Route path={"/"} element={<HomePage/>} />

                {/* Error routes */}
                <Route path={"*"} element={<NotFound/>} />
            </Routes>
        </BrowserRouter>
    );
  };