import { Container } from "./styles";
import emailIcon from "../../assets/social/email.svg";
// import phoneIcon from "../../assets/social/phone.svg"
// import { Form } from "../Form/Form"; 


export function Contact(){

  return(
    <Container id="contact">
      <header>
        <h2>Ping me!</h2>
        <p>Ready to get started on your project?</p>
        <p>Looking for a team member?</p>
      </header>
      <div className="contacts">
        <div>
        <a href="mailto:hello@c3llus.dev"><img src={emailIcon} alt="Email" /></a> 
          <a href="mailto:hello@c3llus.dev">hello@c3llus.dev</a>
        </div>
        {/* <div>
        <a href="tel:+6287778486655"><img src={phoneIcon} alt="Phone No" /></a>
          <a href="tel:+6287778486655">(+62) 87778486655</a>
        </div> */}
      </div>
      {/* TODO: add after enabling cloud run */}
      {/* <Form></Form>  */}
    </Container>
  )
}