import { Container } from "./styles";
import wip from "../../assets/misc/wip.svg"
import externalLink from "../../assets/misc/external-link.svg"
import ScrollAnimation from "react-animate-on-scroll";


export function Project() {
  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">

  

      {/* <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#D4AF37 " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/c3llus" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Minecraft Server</h3>
              <p>
                A Minecraft server running on a Kubernetes cluster.
                Exploring the CI/CD pipeline with Terraform, Ansible, and Jenkins.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>GCP</li>
                <li>Kubernetes</li>
                <li>Java</li>
                <img src={wip} alt="Visit site" width="20rem" height="20rem"/>
              </ul>
            </footer>
          </div>
        </ScrollAnimation> */}


        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#D4AF37 " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://ieeexplore.ieee.org/document/9643176" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              {/* TODO: upload to github */}
              <h3>Movie Poster Genre Classifier</h3>
              <p>
                Use your mobile phone's camera to guess a movie's genre!
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Java</li>
                <li>Python</li>
                {/* <img src={githubIcon} alt="Visit site" width="20rem" height="20rem"/> */}
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#D4AF37 " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/c3llus/backend-boilerplate" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Backend Boilerplate - Go</h3>
              <p>
                All in one server, designed to help create a quick up-and-running service!<br/>
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>GCP</li>
                <li>Go</li>
                <li>Postgres</li>
                <li>Redis</li>
                {/* <img src={wip} alt="Visit site" width="20rem" height="20rem"/> */}
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        {/* <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#D4AF37 " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/c3llus/monolith-go" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Monolith - Go</h3>
              <p>
                All in one server. <br/>
                Exploring Clean Architecture, SOLID, and so much more!!
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>GCP</li>
                <li>Go</li>
                <li>Postgres</li>
                <li>Redis</li>
                <img src={wip} alt="Visit site" width="20rem" height="20rem"/>
              </ul>
            </footer>
          </div>
        </ScrollAnimation> */}


        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#D4AF37 " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/c3llus" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Streaming Service</h3>
              <p>
                Exploring the domain of video processing and streaming.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>GCP</li>
                <li>Rust</li>
                <img src={wip} alt="Visit site" width="20rem" height="20rem"/>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#D4AF37 " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/c3llus" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>AI Pipeline</h3>
              <p>
              TBA
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <img src={wip} alt="Visit site" width="20rem" height="20rem"/>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

      </div>
    </Container>
  );
}