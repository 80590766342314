import { GlobalStyle } from '../../common/styles/global';
import { Header } from '../homepage/components/header';
import { YouLost } from './components/youlost';
import { Container } from './styles';

export const NotFound = () => {
    return (
       <Container>   
            <GlobalStyle/>
            <Header/>
            <YouLost/>
       </Container>
    );
}