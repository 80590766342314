import { Container } from './styles'
import { NavHashLink } from 'react-router-hash-link'
import React, { useEffect, useState } from 'react';

import logo from '../../assets/logo.png';

export function Header() {
  const [isActive, setActive] = useState(false);
  const [showButton, setShowButton] = useState(false);

  function toggleTheme() {
    let html = document.getElementsByTagName('html')[0];
    html.classList.toggle('light');
  }

  function closeMenu() {
    setActive(false);
  }

  useEffect(() => {
    const handleScroll = () => {
      const triggerSection = document.getElementById('trigger-section');
      const scrollPosition = window.scrollY || window.pageYOffset;
      const triggerOffset = triggerSection?.offsetTop || 0;

      setShowButton(scrollPosition > triggerOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container className={`header-fixed ${showButton ? 'show-button' : ''}`}>
      <a href="https://c3llus.dev" rel="noreferrer" className="logo">
        <img src={logo} alt="c3llus.dev" width="40rem"/>
      </a>

      <nav className={isActive ? 'active' : ''}>
        <a
          href="https://calendly.com/c3llus"
          target="_blank"
          rel="noreferrer"
          className="button"
          style={{
            opacity: !showButton ? "0" : "1",
            transition: "all 0.8s",
            visibility: !showButton ? "hidden" : "visible",
          }}
        >
          SCHEDULE CALL
        </a>

        {/* TODO: add nightmode icon */}
        <input
          onChange={toggleTheme}
          type="checkbox"
          className="container_toggle"
          id="switch"
          name="mode"
        />
        <label 
          htmlFor="switch" 
          // className="container_toggle"
        >
          Toggle
        </label>

        <NavHashLink smooth to="/#home" onClick={closeMenu}>
          Home
        </NavHashLink>

        <NavHashLink smooth to="/#project" onClick={closeMenu}>
          Project
        </NavHashLink>
      </nav>

      <div
        aria-expanded={isActive ? 'true' : 'false'}
        aria-haspopup="true"
        aria-label={isActive ? 'Fechar menu' : 'Abrir menu'}
        className={isActive ? 'menu active' : 'menu'}
        onClick={() => {
          setActive(!isActive);
        }}
      ></div>
    </Container>
  );
}
