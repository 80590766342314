import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";


// tech-stacks
import gopher from "../../assets/tech-stacks/gopher.svg";
import java from "../../assets/tech-stacks/java.svg";
import python from "../../assets/tech-stacks/python.svg";
import typescript from "../../assets/tech-stacks/typescript.svg";

import postgres from "../../assets/tech-stacks/postgres.svg";
import mysql from "../../assets/tech-stacks/mysql.svg";
import scylladb from "../../assets/tech-stacks/scylladb.svg";

import redis from "../../assets/tech-stacks/redis.svg";

import nsq from "../../assets/tech-stacks/nsq.png"

import gcp from "../../assets/tech-stacks/gcp.svg";
import aws from "../../assets/tech-stacks/aws.svg";

// tools
import grafana from "../../assets/tools/grafana.svg";
import sentry from "../../assets/tools/sentry.svg";
import datadog from "../../assets/tools/datadog.svg";
import prometheus from "../../assets/tools/prometheus.svg";
import docker from "../../assets/tools/docker.svg";

// exploring
import kubernetes from "../../assets/exploring/kubernetes.svg";
import jenkins from "../../assets/exploring/jenkins.svg";
import nginx from "../../assets/tech-stacks/nginx.svg";
import ferris from "../../assets/tech-stacks/ferris.svg";

const dummy = () => {};

export function About() {
  
  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Hello World!</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
          I'm Marcellus, a Software Engineer.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
          Currently exploring Devops and Site Reliability Engineering!</p>
        </ScrollAnimation>
        {/* <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
          <p>
          XXXX</p>
        </ScrollAnimation> */}

        <br/>

        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <h3>Tech Stacks:</h3>
        </ScrollAnimation>

        <div className="skills"  onMouseEnter={dummy} onMouseLeave={dummy}>

          {/* LANGUAGES */}

          <div className="skill" >
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={gopher} alt="Go" title="Go"/>
              </ScrollAnimation>
          </div>

          <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={java} alt="Java" title="Java"/>
              </ScrollAnimation>
          </div>

          <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={python} alt="Python" title="Python"/>
              </ScrollAnimation>
          </div>

          <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={typescript} alt="Typescript" title="Typescript"/>
              </ScrollAnimation>
          </div>
          
          {/* DATABASES */}

          <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={postgres} alt="PostgreSQL" title="PostgreSQL" />
              </ScrollAnimation>
          </div>

          <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={mysql} alt="MySQL" title="MySQL" />
              </ScrollAnimation>
          </div>

          <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={scylladb} alt="ScyllaDB" title="ScyllaDB" />
              </ScrollAnimation>
          </div>

          <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={redis} alt="Redis" title="Redis" />
              </ScrollAnimation>
          </div>


          {/* MESSAGE QEUEUES */}

          <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={nsq} alt="NSQ" title="NSQ"/>
              </ScrollAnimation>
          </div>

          {/* CLOUD PROVIDER */}
         
          <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={gcp} alt="GCP" title="GCP" />
              </ScrollAnimation>
          </div>

          <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={aws} alt="AWS" title="AWS" />
              </ScrollAnimation>
          </div>

          {/* WEB SERVER */}


        </div>

        <br/>

        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <h3>Tools:</h3>
        </ScrollAnimation>
        <div className="skills"  onMouseEnter={dummy} onMouseLeave={dummy}>

          {/* OBSERVABILITY */}

          <div className="skill" >
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={grafana} alt="Grafana" title="Grafana"/>
              </ScrollAnimation>
          </div>

          <div className="skill" >
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={sentry} alt="Sentry" title="Sentry"/>
              </ScrollAnimation>
          </div>
          
          <div className="skill" >
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={datadog} alt="Datadog" title="Datadog"/>
              </ScrollAnimation>
          </div>

          <div className="skill" >
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={prometheus} alt="Prometheus" title="Prometheus"/>
              </ScrollAnimation>
          </div>

          {/* CONTAINER */}
          <div className="skill" >
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={docker} alt="Docker" title="Docker"/>
              </ScrollAnimation>
          </div>

        </div>

        <br/>

        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <h3>Currently Exploring:</h3>
        </ScrollAnimation>
        <div className="skills"  onMouseEnter={dummy} onMouseLeave={dummy}>

          {/* OBSERVABILITY */}

          <div className="skill" >
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={kubernetes} alt="Kubernetes" title="Kubernetes"/>
              </ScrollAnimation>
          </div>

          <div className="skill" >
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={jenkins} alt="Jenkins" title="Jenkins"/>
              </ScrollAnimation>
          </div>

          <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={nginx} alt="NGINX" title="NGINX" />
              </ScrollAnimation>
          </div>

           {/* LANGUAGES */}
           <div className="skill">
              <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
                <img src={ferris} alt="Rust" title="Rust" />
              </ScrollAnimation>
          </div>
          
        </div>

        
      </div>
      {/* <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
          <img src={profile} alt="Profile" width="50%" height="50%"/>
        </ScrollAnimation>
      </div> */}
    </Container>
  )
}
