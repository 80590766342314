
import {Router} from './route'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <Router/>
  )
}

export default App
